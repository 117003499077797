












































































































































import { Vue, Component } from 'vue-property-decorator'
import HeaderBox from '@/components/HeaderBox.vue'
import auth from '@/common/auth'
import cache from '@/common/cache';

interface AddDealerForm {
  name: string
  mobile: string
  record_url: string
  id_card_front_url: string
  id_card_back_url: string
  consignee: Array<any>
}

interface UploadKey {
  title: string
  srcKey: string
}
@Component({
  components: {
    HeaderBox
  }
})
export default class AddDealer extends Vue {
  carDealerRules = {
    name: {
      required: true,
      validator: auth.checkInput({
        nullValue: '姓名不能为空',
        haveValue: '请输入1~10个汉字、数字、字母',
        regex: 'regexp_chinese_ten'
      }),
      trigger: 'blur'
    },
    mobile: {
      required: true,
      validator: auth.checkInput({
        nullValue: '电话不能为空',
        haveValue: '请输入正确的委托人电话',
        regex: 'regexp_mobile'
      }),
      trigger: 'blur'
    },
    record_url: {
      required: true,
      message: '请上传图片',
      // trigger: 'blur'
    },
  }
  consignorUploadImgs: UploadKey[] = [
    { title: '提车/签约备案表', srcKey: 'record_url' },
    { title: '身份证正面', srcKey: 'id_card_front_url' },
    { title: '身份证反面', srcKey: 'id_card_back_url' }
  ]
  consigneeUploadImgs: UploadKey[] = [
    { title: '身份证正面', srcKey: 'id_card_front_url' },
    { title: '身份证反面', srcKey: 'id_card_back_url' }
  ]
  addDealerForm: any = {
    consignor: {
      name: '',
      mobile: '',
      record_url: '',
      id_card_front_url: '',
      id_card_back_url: '',
      isErrMsg: false
    },
    consignee: []
  }
  dialogImageUrl: string = ''
  dialogVisible: boolean = false
  consigneeArr = []
  uploadData = {
    "perm_code": 'view_car'
  }
  // headers = {
  //   Authorization: cache.getLocalStorageData('access_token')
  // }
  headers = {}
  uploadUrl = ''
  consignorUrls = []
  consignee = []
  arrList = []
  consignorPicErrMsg = false
  isTapDone = false
  uploadExtraData = {}

  // get uploadUrl() {
  //   return '/api/v1/source_handler/upload/image/car_dealer';
  // }

  checkInput(options) {
    return (rule, value, cb) => {
      let message = '';
      if (value === '' && options.nullValue) {
        message = options.nullValue;
      } else if (value !== '' && options.haveValue && !auth.regexpMap[options.regex].test(value)) {
        message = options.haveValue;
      }
      message ? cb(new Error(message)) : cb();
    };
  }

  handleUploadSuccess(res, key, subKey, index) {
    const { code, data } = res;
    if (code !== 0) return;
    if (key === 'consignor') {
      this.addDealerForm[key][subKey] = data[0];
    } else {
      this.addDealerForm[key][index][subKey] = data[0];
    }
  }

  checkUploadImgRules(file) {
    const typeList = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp']
    const isType = typeList.includes(file.type)
    const isLt10M = (file.size / 1024 / 1024) < 10
    if (!isType) this.$message.error('仅支持上传格式为jpg、png、jpeg、bmp的图片')
    if (!isLt10M) this.$message.error('上传图片不能大于10M')
    return isType && isLt10M
  }

  beforeUpload(file) {
    return new Promise(async (resolve, reject) => {
      if (!this.checkUploadImgRules(file)) reject(false);

      const { code, data } = await this.$rest.global.getUploadConfig('car_dealer');

      if (code !== 0) return false;

      this.headers = {
        'lixin-token': data.token,
      }

      this.uploadUrl = data.url;

      this.uploadExtraData = {
        'upload-type': 'image',
        count: '1',
        path1: data.paths[0],
      };
      resolve(true);
    });
  }

  handleDelconsignor (key) {
    this.addDealerForm[key] = ''
  }

  handleDelImage (type, key, index) {
    if (type === 'consignor') {
      this.addDealerForm[type][key] = '';
    } else {
      this.addDealerForm[type][index][key] = '';
    }
  }

  handleAddConsignee () {
    if (this.addDealerForm.consignee.length >= 10) {
      this.$message.error('最多可添加10个被委托人');
      return;
    }
    this.addDealerForm.consignee.push({
      name: '',
      mobile: '',
      id_card_front_url: '',
      id_card_back_url: '',
      isErrMsg: false
    });
  }

  handleDelConsignee (index) {
    this.$confirm('确定要删除该被委托人吗？', '删除', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      if (index !== -1) this.addDealerForm.consignee.splice(index, 1)
    }).catch(() => {}); // catch 必须要，否则点击取消时会提示没有绑定取消事件
  }

  formatForm () {
    let form = JSON.parse(JSON.stringify(this.addDealerForm));
    this.consignorUploadImgs.forEach(item => {
      form['consignor'][item.srcKey] = this.addDealerForm['consignor'][item.srcKey].upload_image;
    })
    const consignee = this.addDealerForm['consignee'];
    consignee.forEach((item, index) => {
      form['consignee'][index]['id_card_front_url'] = consignee[index]['id_card_front_url'].upload_image;
      form['consignee'][index]['id_card_back_url'] = consignee[index]['id_card_back_url'].upload_image;
    })
    return form;
  }

  async handleSave (formName) {
    let formValid: boolean = true;

    const consignor = this.addDealerForm['consignor'];
    let consignorErrmsg = false;
    // 委托人图片验证
    if (!(consignor.id_card_front_url && consignor.id_card_back_url && consignor.record_url)) {
      consignorErrmsg = true;
    } else {
      consignorErrmsg = false;
    }
    this.addDealerForm['consignor'].isErrMsg = consignorErrmsg;

    (this.$refs['consignorValidateForm'] as any).validate((valid) => {
      if (!valid || consignorErrmsg) formValid = false
    });

    if (this.addDealerForm.consignee.length > 0) {
      if ((this.$refs as any)['consigneeValidateForm'] === undefined) return;
      const count = (Array.from((this.$refs as any)['consigneeValidateForm']) || []).length;
      for(let i = 0; i < +count; i++) {
        const consignee = this.addDealerForm.consignee[i];
        let consigneeErrMsg = false;
        if (!(consignee.id_card_front_url && consignee.id_card_back_url)) {
          consigneeErrMsg = true;
        } else {
          consigneeErrMsg = false;
        }
        this.addDealerForm.consignee[i].isErrMsg = consigneeErrMsg;
        (this.$refs['consigneeValidateForm'] as any)[i].validate((valid) => {
          if (!valid || consigneeErrMsg) formValid = false;
        });
      }
    }
    if (!formValid) return;
    this.isTapDone = true;
    const form = this.formatForm();
    const { code } = await this.$rest.dealer.addDealer(form);
    this.isTapDone = false;
    if (code === 0) {
      this.$message.success('保存成功');
      this.$router.push('/dealer/index');
    }
  }
}
