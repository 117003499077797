































































import { Vue, Component, Mixins, Watch } from 'vue-property-decorator'
import HeaderBox from '@/components/HeaderBox.vue'

interface searchParams {
  page: number,
  count: number
  name: string
  mobile: string
}
@Component({
  components: {
    HeaderBox
  }
})
export default class DealerIndex extends Vue {
  loading: boolean = false
  tableData = []
  searchParams: searchParams = {
    page: 1,
    count: 10,
    name: '',
    mobile: ''
  }
  totalNum: number = 0

  mounted () {
    this.getDealers();
  }

  async getDealers () {
    this.loading = true;
    const { code, data } = await this.$rest.dealer.getDealerList(this.searchParams);
    this.loading = false;
    if (code === 0) {
      this.tableData = data.infos;
      this.totalNum = data.total_count;
    }
  }

  handelSearch () {
    this.searchParams.page = 1;
    this.getDealers();
  }

  handleReset () {
    this.searchParams.page = 1;
    this.searchParams.name = '';
    this.searchParams.mobile = '';
    this.getDealers();
  }

  handleCurrentChange () {
    this.getDealers();
  }

  handleGo (path, id) {
    const { href } = this.$router.resolve({ path, query: { id } });
    window.open(href, '_blank');
  }

  // @Watch('$route', { immediate: true, deep: true }) 
  // show() {
  //   this.getDealers();
  // }
}
