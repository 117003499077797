import { render, staticRenderFns } from "./AddDealer.vue?vue&type=template&id=b5a29fb6&"
import script from "./AddDealer.vue?vue&type=script&lang=ts&"
export * from "./AddDealer.vue?vue&type=script&lang=ts&"
import style0 from "./AddDealer.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports