








































































import { Vue, Component } from 'vue-property-decorator'
import HeaderBox from '@/components/HeaderBox.vue'
import ImagePreview from '@/components/ImagePreview.vue'

@Component({
  components: {
    HeaderBox,
    ImagePreview
  }
})
export default class DealerDetail extends Vue {
  consignor = {}
  consignee = []
  currentImages = []
  showPreviewWindow = false

  async created() {
    const { id } = this.$route.query;
    const { code, data } = await this.$rest.dealer.getDealerId(id);
    if (code === 0) {
      const infos = data.infos;
      this.consignor = infos.consignor;
      this.consignee = infos.consignee;
    }
  }

  handleLookBigImages (urls) {
    this.currentImages = this.formatBigImages([urls]);
    this.showPreviewWindow = true;
  }
}
